import {notification} from "antd";

export default function infoAlert(
    featureName: string,
    userMessage: string,
    actionName: string,
    actionContext: object = {},
    componentName?: string,
) {
    // track(
    //     featureName,
    //     "Info Alert",
    //     {message: userMessage, triggerAction: actionName},
    //     componentName
    // );
    notification.info({message: userMessage});
}
