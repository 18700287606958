import React from "react";

import {Form, Input} from "antd";

import {LockOutlined} from "@ant-design/icons";
import {NamePath} from "rc-field-form/lib/interface";

type PasswordEditInputProps = {
    label?: React.ReactNode,
    name?: NamePath,
}

const InputPasswordEdit: React.VFC<PasswordEditInputProps> = ({
    label,
    name,
}) => <Form.Item
    label={label}
    name={name}
    rules={[
        {required: true, message: "Das Passwort ist leer."},
        {min: 8, message: "Das Passwort muss mindesten 8 Zeichen lang sein."},
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (value === getFieldValue("email")) {
                    return Promise.reject(new Error("Das Passwort darf nicht identisch mit der E-Mail-Adresse sein."));
                }
                if (value === getFieldValue("name")) {
                    return Promise.reject(new Error("Das Passwort darf nicht identisch mit dem Vornamen und Zunamen sein."));
                }
                return Promise.resolve();
            },
        }),
    ]}
><Input.Password
    prefix={<LockOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
    size="large"
/></Form.Item>;

export default InputPasswordEdit;
