import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import ErrorBoundary from "./components/ErrorBoundary";
import "./index.less";
import App from "./App";
import {unregister} from "./registerServiceWorker";

axios.defaults.headers.common["Cache-Control"] = "no-cache, no-store";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;

ReactDOM.render(
    <ErrorBoundary><App/></ErrorBoundary>,
    document.getElementById("root")
);

unregister();
