import React from "react";
import useBrand from "../../hooks/useBrand";
import "./component.css";

const FooterNav: React.VFC = () => {
    const brand = useBrand();
    return <ul className="footerNav">
        <li><a href={brand.imprintUrl} target="_blank" rel="noopener noreferrer">Impressum</a></li>
        <span className="delimiter"> • </span>
        <li><a href={brand.privacyUrl} target="_blank" rel="noopener noreferrer">Datenschutz</a></li>
    </ul>;
}

export default FooterNav;
