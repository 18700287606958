import React, {useState} from "react";
import axios from "axios";

import {Form} from "antd";

import InputUsername from "../../../components/FormItem/InputUsername";
import Headline from "../../../components/Headline/Headline";
import {useUserAlert} from "../../../_shared/components/UserAlert";
import styles from "./Form.module.css";
import ButtonSubmit from "../../../components/FormItem/ButtonSubmit";
import tld from "../../../tld";
import useBrand from "../../../hooks/useBrand";

type EmailFormProps = {
    onDone?: (email: string) => void,
}

const EmailForm: React.VFC<EmailFormProps> = ({onDone = () => {}}) => {

    const brand = useBrand();
    const [loading, setLoading] = useState<boolean>(false);
    const userAlert = useUserAlert("PasswordReset", "EmailForm");

    const onFinish = async (values) => {
        setLoading(true);
        const url = tld(process.env.REACT_APP_URL_API_AUTH) + "/v1/workflows/passwordResets";
        const email = values.username;
        try {
            await axios.post(url, {email});
            onDone(email);
        } catch (e) {
            userAlert.error(e, "onFinish");
            setLoading(false);
        }
    };

    return <section className={styles.form}>
        <Headline>Passworthilfe</Headline>
        <p>Geben Sie die E-Mail-Adresse ein, die mit Ihrem {brand.name}-Konto verbunden ist.</p>
        <Form onFinish={onFinish}>
            <InputUsername checkEmail={true}/>
            <ButtonSubmit loading={loading}>Weiter</ButtonSubmit>
        </Form>
    </section>;
}

export default EmailForm;
