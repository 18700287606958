import React from "react";
import {Link} from "react-router-dom";

import Headline from "../../../components/Headline/Headline";
import styles from "./Form.module.css";

const FinishedInfo: React.VFC = () => <section className={styles.form}>
    <Headline>Aktualisierung Passwort</Headline>
    <p>Ihr Passwort wurde erfolgreich geändert.</p>
    <p><Link to="/">Weiter zur Anmeldung</Link></p>
</section>;

export default FinishedInfo;
