import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {BugsnagErrorBoundary} from "@bugsnag/plugin-react";

const ErrorBoundary: React.FC = (props) => {
    if (process.env.REACT_APP_BUGSNAG_KEY) {
        Bugsnag.start({
            apiKey: process.env.REACT_APP_BUGSNAG_KEY,
            collectUserIp: false,
            plugins: [new BugsnagPluginReact()],
            logger: null, // make Bugsnag silent in console
            appVersion: process.env.REACT_APP_REVISION?.substring(0, 8),
        });
        const ErrorBoundary: BugsnagErrorBoundary|undefined
                = Bugsnag.getPlugin("react")?.createErrorBoundary(React) || undefined;
        return ErrorBoundary ? <ErrorBoundary>{props.children}</ErrorBoundary> : <>{props.children}</>;
    } else {
        return <>{props.children}</>;
    }
}

// push also console errors to bugsnag
if (process.env.REACT_APP_BUGSNAG_KEY) {
    window.console = ((console => {
        const isError = v => toString.call(v) === "[object Error]";
        return {
            ...console,
            log: msg => {
                console.log(msg);
                isError(msg) && Bugsnag.notify(msg);
            },
            warn: msg => {
                console.warn(msg);
                isError(msg) && Bugsnag.notify(msg);
            },
            error: msg => {
                console.error(msg);
                Bugsnag.notify(isError(msg) ? msg : new Error("console: " + msg));
            }
        };
    })(window.console));
}

export default ErrorBoundary;
