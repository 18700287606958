import React from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';
import {Helmet} from "react-helmet";

import FooterNav from './components/FooterNav';
import LoadableSpin from './components/LoadableSpin';
import Logo from "./components/Logo";

import {Layout} from "antd";

import styles from "./App.module.css";
import PasswordResetScene from "./scenes/PasswordResetScene";
import useBrand from "./hooks/useBrand";

const {Content, Footer} = Layout;

const Loading = () => <LoadableSpin/>;
const IndexSceneLazy = React.lazy(() => import("./scenes/IndexScene"));
const IndexScene = props => (
        <React.Suspense fallback={<Loading/>}><IndexSceneLazy {...props}/></React.Suspense>
);
const VerificationSceneLazy = React.lazy(() => import("./scenes/VerificationScene"));
const VerificationScene = props => (
        <React.Suspense fallback={<Loading/>}><VerificationSceneLazy {...props}/></React.Suspense>
);

const App: React.VFC = () => {
    const brand = useBrand();
    return <Router>
        <Helmet>
            <title>{brand.name} - Anmeldung</title>
            <meta name="apple-mobile-web-app-title" content="{brand.name}"/>
        </Helmet>
        <Layout style={{minHeight: '100%'}}>
            <div style={{borderBottom: "solid 1px #d9d9d9", height: 64, textAlign: "center"}}>
                <Link to="/"><Logo style={{marginTop: 12, marginLeft: 20, marginRight: 20}}/></Link>
            </div>
            <Content style={{
                padding: 25,
                paddingBottom: "10%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Switch>
                    <Route exact path="/:verificationCode([0-9a-fA-f\-_]{21,24})" component={VerificationScene}/>
                    <Route exact path="/passwordReset" component={PasswordResetScene}/>
                    <Route component={IndexScene}/>
                </Switch>
            </Content>
            <Footer className={styles.footer}><FooterNav/></Footer>
        </Layout>
    </Router>;
}

export default App;
