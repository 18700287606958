import {notification} from "antd";

export default function warningAlert(
    featureName: string,
    userMessage: string,
    description: string|undefined,
    actionName: string,
    actionContext: object = {},
    componentName?: string,
) {
    // track(
    //     featureName,
    //     "Warning Alert",
    //     {message: userMessage, triggerAction: actionName, description },
    //     componentName
    // );
    notification.warning({message: userMessage, description });
}
