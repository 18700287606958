import React, {useState} from "react";
import axios from "axios";

import {Form} from "antd";

import Headline from "../../../components/Headline/Headline";
import {useUserAlert} from "../../../_shared/components/UserAlert";
import styles from "./Form.module.css";
import InputPasswordEdit from "../../../components/FormItem/InputPasswordEdit";
import ButtonSubmit from "../../../components/FormItem/ButtonSubmit";
import tld from "../../../tld";

type PinFormProps = {
    verificationId: string,
    onDone?: () => void,
}

const PasswordForm: React.VFC<PinFormProps> = ({verificationId, onDone = () => {}}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const userAlert = useUserAlert("PasswordReset", "PasswordForm");

    const onFinish = async (values) => {
        setLoading(true);
        const url = tld(process.env.REACT_APP_URL_API_AUTH) + "/v1/workflows/verifications";
        try {
            await axios.patch(url, {id: verificationId, password: values.password});
            onDone();
        } catch (e) {
            userAlert.error(e, "onFinish");
            setLoading(false);
        }
    };

    return <section className={styles.form}>
        <Headline>Neues Passwort erstellen</Headline>
        <p>Wir fragen nach diesem Passwort, wenn Sie sich anmelden.</p>
        <Form onFinish={onFinish} layout="vertical">
            <InputPasswordEdit label="Neues Passwort" name="password"/>
            <ButtonSubmit loading={loading}>Weiter</ButtonSubmit>
        </Form>
    </section>;
}

export default PasswordForm;
