import React, {useState} from "react";
import axios from "axios";

import {Form, Input} from "antd";

import Headline from "../../../components/Headline/Headline";
import {useUserAlert} from "../../../_shared/components/UserAlert";
import styles from "./Form.module.css";
import ButtonSubmit from "../../../components/FormItem/ButtonSubmit";
import tld from "../../../tld";

type PinFormProps = {
    email: string,
    onDone?: (verificationId: string) => void,
}

const PinForm: React.VFC<PinFormProps> = ({email, onDone = () => {}}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const userAlert = useUserAlert("PasswordReset", "PinForm");

    const onFinish = async (values) => {
        setLoading(true);
        const url = tld(process.env.REACT_APP_URL_API_AUTH) + "/v1/workflows/passwordResets/verifications";
        try {
            const response = await axios.post(url, {email, pin: values.pin}, {
                validateStatus: (status: number) => {
                    return (status >= 200 && status < 300) || 404 === status;
                },
            });
            switch (response.status) {
                case 404:
                    setFailed(true);
                    setLoading(false);
                    break;
                default:
                    onDone(response.data.id);
            }
        } catch (e) {
            userAlert.error(e, "onFinish");
            setFailed(true);
            setLoading(false);
        }
    };

    return <section className={styles.form}>
        <Headline>Überprüfung Ihrer Identität</Headline>
        <p>Aus Sicherheitsgründen müssen wir Ihre Identität überprüfen. Wir haben einen Code an die E-Mail {email} gesendet. Geben Sie ihn bitte unten ein.</p>
        <Form onFinish={onFinish}>
            <Form.Item
                label="Code eingeben"
                name="pin"
                rules={[{required: true, message: "Es ist kein Code angegeben."}]}
                validateStatus={failed ? "error" : undefined}
                help={failed ? "Der Code ist nicht gültig" : undefined}
            ><Input size={'large'} /></Form.Item>
            <ButtonSubmit loading={loading}>Weiter</ButtonSubmit>
        </Form>
    </section>;
}

export default PinForm;
