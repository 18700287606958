import tld from "../tld";

const brands: {[domain: string]: Brand} = {
    "flowpilot.finance": {
        domain: "flowpilot.finance",
        name: "FlowPilot",
        imprintUrl: "https://flowpilots.de/impressum/",
        privacyUrl: "https://flowpilots.de/datenschutzerklaerung/",
    },
    "plusfix.finance": {
        domain: "flowpilot.finance",
        name: "PlusFix",
        imprintUrl: "https://plusfix.de/impressum/",
        privacyUrl: "https://plusfix.de/datenschutz/",
    },
};

const defaultBrand: Brand = {
    domain: "localhost",
    name: "App",
    imprintUrl: "#imprint",
    privacyUrl: "#privacy",
}

export default function useBrand(): Brand {
    return brands[tld()] ?? defaultBrand;
}
