import React from "react";
import {Col, Row} from "antd";
import {ValidateStatus} from "antd/lib/form/FormItem";
import styles from "./MiniStage.module.css";

type MiniStageProps = {
    value?: string,
    validateStatus?: ValidateStatus,
}

const MiniStage: React.FC<MiniStageProps> = ({children}) => {
    return <section className={styles.stage}><Row><Col offset={5} span={14}>{children}</Col></Row></section>;
}

export default MiniStage;
