import React from "react";
import useBrand from "../../hooks/useBrand";

type LogoProps = {
    height?: string,
    style?: React.CSSProperties,
}

const Logo: React.VFC<LogoProps> = (
    {
        height = "40px",
        style,
    }
) => {
    const brand = useBrand();
    return <img src="/asset/logo.png" alt={brand.name} height={height} style={style}/>;
}

export default Logo;
