import React from "react";

import {Button, Form} from "antd";

type SubmitButtonProps = {
    loading?: boolean | {
        delay?: number;
    },
}

const ButtonSubmit: React.FC<SubmitButtonProps> = ({
    loading,
    children,
}) => <Form.Item><Button
        loading={loading}
        type="primary"
        htmlType="submit"
        size="large"
>{children}</Button></Form.Item>;

export default ButtonSubmit;
