import React, {useCallback, useState} from "react";
import EmailForm from "./components/EmailForm";
import MiniStage from "../../components/MiniStage";
import PinForm from "./components/PinForm";
import PasswordForm from "./components/PasswordForm";
import FinishedInfo from "./components/FinishedInfo";

const RestoreScene: React.VFC = () => {

    const [email, setEmail] = useState<string|undefined>();
    const [verificationId, setVerificationId] = useState<string|undefined>();
    const [finished, setFinished] = useState<boolean>(false);

    const onEmailEntered = useCallback(
        (email: string) => setEmail(email),
        [setEmail]
    );
    const onPinEntered = useCallback(
        (verificationId: string) => setVerificationId(verificationId),
        [setVerificationId]
    );
    const onPasswordSet = useCallback(
        () => setFinished(true),
        [setFinished]
    );

    const content = undefined === email
        ? <EmailForm onDone={onEmailEntered}/>
        : (undefined === verificationId
            ? <PinForm email={email} onDone={onPinEntered}/>
            : (finished ? <FinishedInfo/> : <PasswordForm verificationId={verificationId} onDone={onPasswordSet}/>)
        );

    return <MiniStage>{content}</MiniStage>;
}

export default RestoreScene;
