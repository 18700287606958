import React from "react";
import {Form, Input} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {ValidateStatus} from "antd/lib/form/FormItem";
import {Rule} from "antd/lib/form";

type InputUsernameProps = {
    value?: string,
    validationStatus?: ValidateStatus,
    checkEmail?: boolean,
}

const InputUsername: React.VFC<InputUsernameProps> = ({
    value,
    validationStatus,
    checkEmail = false,
}) => {
    const rules: Rule[] = [
        {required: true, message: "Die E-Mail-Adresse ist leer."},
    ];
    if (checkEmail) {
        rules.push({type: "email", message: "Die E-Mail-Adresse ist ungültig."});
    }
    return (undefined !== value)
        ? null
        : <Form.Item
            validateStatus={validationStatus}
            name="username"
            rules={rules}
        ><Input
            prefix={<UserOutlined style={{color: "rgba(0,0,0,.25)"}}/>}
            placeholder="E-Mail-Adresse"
            size={"large"}
        /></Form.Item>;
}

export default InputUsername;
